import type { ReactElement } from 'react';
import Image from 'next/legacy/image';
import styled from 'styled-components';
import type { Card as CardSchema } from '../../../amplienceTypes/schemas/exported/card-schema';
import type { StoryContentPage as StorySchema } from '../../../amplienceTypes/schemas/exported/story-schema';
import type { ResponsiveImage1 } from '../../../amplienceTypes/partials/exported/responsive-image-schema';
import { removeTagsFromHTML, truncate } from '../../../utilities/parsers';
import { colors, media } from '../../shared/core/styles';
import { useAppContext } from '../../../utilities/context/static/AppContext';

interface StoryCardInterface {
  card?: CardSchema;
  story?: StorySchema;
}

const S = {
  CardWrapper: styled.div`
    position: relative;
    width: 75vw;
    max-width: 480px;
    scroll-snap-align: center;
    scroll-margin-left: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 20px;

    &:first-child {
      margin-left: 20px;
    }

    @media ${media.greaterThan('sm')}, print {
      min-width: 290px;
      width: calc(33.3333vw - 54px);
      max-width: 420px;
      scroll-snap-align: center;
      scroll-margin: 0 36px;
      margin-right: 36px;

      &:first-child {
        margin-left: 36px;
      }

      &:last-child {
        margin-right: 36px;
      }
    }

    @media ${media.greaterThan('lg')}, print {
      margin-right: 2vw;

      &:first-child {
        margin-left: 3.2vw;
      }

      &:last-child {
        margin-right: 3.2vw;
      }
    }

    @media ${media.greaterThan('xl')}, print {
      .has-open-sideNavigation & {
        min-width: 290px;
        width: calc(33.3333vw - 136px);
        width: calc(33.3333vw - 250px / 3 - 3.5vw);
      }
    }
  `,

  Card: styled.div`
    display: flex;
    height: 100%;
    padding: 0;
    background-color: ${colors.WHITE};
    border-radius: 2px;
    box-shadow: 0 1px 9px -5px rgba(48 48 48 / 15%);
    content-visibility: visible;
    position: relative;
    width: 100%;
  `,

  Mask: styled.a`
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: ${colors.WHITE};
    overflow: hidden;

    &:hover,
    &:focus {
      .card-image::after {
        opacity: 0.2;
      }

      .card-link {
        color: ${colors.WHITE};
        background: ${colors.NERO_GREY};
      }
    }

    @media ${media.greaterThan('sm')}, print {
      &:hover,
      &:focus {
        .card-link {
          color: ${colors.ACCESSIBILITY_GREY};
          background: 0 0;
        }
      }
    }
  `,

  DateTag: styled.div`
    font-size: min(14px, 2.7vw);
    width: 5.4em;
    max-height: 5.8em;
    padding: 1.2em 0;
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 15px;
    color: ${colors.WHITE};
    font-weight: 700;
    text-align: center;
    background: ${colors.BLACK};
    z-index: 30;

    @media ${media.greaterThan('sm')}, print {
      font-size: 11px;
    }

    @media ${media.greaterThan('lg')}, print {
      font-size: 13px;
    }
  `,

  DateTagMonth: styled.span`
    display: block;
  `,

  DateTagDate: styled.span`
    display: block;
    font-size: 2em;
  `,

  DateTagYear: styled.span`
    display: block;
    font-size: 1.5em;

    [lang^='ja'] &,
    [lang^='ko'] & {
      font-size: 1.1em;
    }
  `,

  ImageWrapper: styled.div`
    position: relative;
    padding-bottom: 85%;
    z-index: 10;
    overflow: hidden;
  `,

  CardImage: styled.div`
    position: absolute;
    inset: 0;
    aspect-ratio: auto;

    &::after {
      position: absolute;
      inset: 0;
      content: '';
      background: ${colors.BLACK};
      opacity: 0;
      transition: opacity 0.5s;
      transform: translate3d(0, 0, 0);

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    .has-mouse & {
      will-change: transform;
      transform-origin: 50% 60%;
      transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);

      &:hover,
      &:focus {
        transform: scale(1.02);
      }
    }
  `,

  ImageLazyLoad: styled(Image)`
    left: 0;
    position: absolute;
    right: 0;
    visibility: visible;
    height: 100%;
    object-fit: cover;
    width: 100%;

    &[data-src] {
      visibility: hidden;
    }
  `,

  Category: styled.div`
    position: absolute;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2em;
    letter-spacing: 0.26em;
    text-transform: uppercase;
    color: ${colors.WHITE};
    bottom: 0;
    width: 100%;
    padding: 30px 15px 15px;
    background: linear-gradient(
      0deg,
      rgba(0 0 0 / 50%) 0%,
      rgba(0 0 0 / 20%) 45%,
      rgba(0 0 0 / 10%) 65%,
      rgba(0 0 0 / 0%) 100%
    );
  `,

  Content: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    bottom: 0;
    height: auto;

    @media ${media.greaterThan('sm')}, print {
      width: calc(100% + 1px);
      overflow: visible;
      z-index: 20;
    }
  `,

  ContentInner: styled.div`
    padding: 15px 20px 0;

    @media ${media.greaterThan('sm')}, print {
      display: table-cell;
      background: ${colors.WHITE};
      vertical-align: middle;
    }
  `,

  Title: styled.h3`
    margin: 0;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;

    @media ${media.greaterThan('md')}, print {
      font-size: 20px;
    }
  `,

  DescriptionWrapper: styled.div`
    position: relative;
    margin: 15px 0;
    max-height: 60px;
    overflow: hidden;
    margin-bottom: 0;

    &::before {
      content: '';
      position: absolute;
      background: rgba(0 0 0 / 0%)
        linear-gradient(to bottom, rgba(255 255 255 / 0%) 0, ${colors.WHITE} 75%) repeat scroll 0 0;
      top: 40px;
      left: 0;
      width: 100%;
      height: 20px;
    }

    @supports (display: -webkit-box) {
      &::before {
        content: none;
      }
    }
  `,

  Description: styled.p`
    margin: 0;
    color: ${colors.ACCESSIBILITY_GREY};
    text-align: center;

    @supports (display: -webkit-box) {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `,

  LinkWrapper: styled.div`
    padding: 0 0 20px;
    text-align: center;
    position: relative;
    margin-top: 10px;
    height: 35px;
    z-index: 30;
    background: ${colors.WHITE};
  `,

  Link: styled.span`
    display: inline-block;
    font-size: 14px;
    line-height: 1.2;
    padding: 0;
    border: 0 none;
    border-bottom: 2px solid currentcolor;
    color: ${colors.NERO_GREY};
    font-weight: 700;
    transition: 0.1s background-color linear, 0.1s color linear, 0.1s border-color linear;

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

export const StoryCard = ({ card, story }: StoryCardInterface): ReactElement => {
  const { bcp47Locale } = useAppContext();

  const buttonUrl = card?.card?.button?.url || story?.card?.button?.url;
  const buttonText = card?.card?.button?.text || story?.card?.button?.text;
  const buttonTarget = card?.card?.button?.target || story?.card?.button?.target;
  const date = card?.card?.date || story?.date;
  const image =
    card?.card?.image ||
    story?.card?.image ||
    (story?.main?.media && (story?.main?.media[0].responsiveImage as ResponsiveImage1))?.images
      ?.desktop;
  const title = card?.card?.title || story?.card?.title || story?.main?.title;
  const description = truncate(
    removeTagsFromHTML(
      card?.card?.description ||
        story?.card?.description ||
        (story?.main?.intro?.html as string | undefined)
    ),
    200
  );

  const category = card?.card?.category || story?.category;

  const makeDateElements = (dateStr: string) => {
    const dateNum = new Date(Date.parse(dateStr));
    const dateVal = dateNum.toLocaleDateString(bcp47Locale, { day: 'numeric' });
    const month = dateNum.toLocaleDateString(bcp47Locale, { month: 'short' });
    const year = dateNum.toLocaleDateString(bcp47Locale, { year: 'numeric' });

    return (
      <S.DateTag>
        <S.DateTagMonth>{month}</S.DateTagMonth>
        {card ? (
          <>
            {dateStr.split('-').length === 3 && <S.DateTagDate>{dateVal}</S.DateTagDate>}
            {dateStr.split('-').length === 2 && <S.DateTagYear>{year}</S.DateTagYear>}
          </>
        ) : (
          <S.DateTagYear>{year}</S.DateTagYear>
        )}
      </S.DateTag>
    );
  };

  return (
    <S.CardWrapper>
      <S.Card>
        {/* TODO To convert to next SPA link later */}
        <S.Mask
          href={buttonUrl || '#'}
          target={buttonTarget === 'Same Window/Tab' ? '_self' : '_blank'}
          rel="noreferrer"
        >
          {date && makeDateElements(date)}
          <S.ImageWrapper>
            <S.CardImage className="card-image">
              <S.ImageLazyLoad
                src={`https://${image?.defaultHost}/i/${image?.endpoint}/${encodeURI(
                  image?.name ?? ''
                )}?fmt=auto&w=640&upscale=false&sm=aspect&aspect=420:357&scaleFit=poi&poi={$this.metadata.pointOfInterest.x},{$this.metadata.pointOfInterest.y},{$this.metadata.pointOfInterest.w},{$this.metadata.pointOfInterest.h}`}
                alt="card"
                decoding="async"
                className="card-image__lazyload js-lazyLoadImage"
                layout="fill"
              />
            </S.CardImage>
            {category && <S.Category>{category}</S.Category>}
          </S.ImageWrapper>

          <S.Content>
            <S.ContentInner>
              {title && (
                <S.Title
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
              )}
              {description && (
                <S.DescriptionWrapper>
                  <S.Description
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </S.DescriptionWrapper>
              )}
            </S.ContentInner>
          </S.Content>

          <S.LinkWrapper>
            {buttonText && <S.Link className="card-link">{buttonText}</S.Link>}
          </S.LinkWrapper>
        </S.Mask>
      </S.Card>
    </S.CardWrapper>
  );
};
